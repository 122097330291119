import "./App.css";
import Payment from "./Payment";
import Return from "./Return";

import PurchaseElement from "./components/PurchaseElement/PurchaseElement";
import Header from "./components/Header/Header";
import MainPageContent from "./components/MainPageContent/MainPageContent";
import Footer from "./components/Footer/Footer";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";


function App() {
  return (
    <main>
      <Header />
      <MainPageContent />
      <Footer />
      <Router>
        <Routes>
          <Route path="/" element={<Payment />} />
          <Route path="/return" element={<Return />} />
        </Routes>
      </Router>
    </main>
  );
}

export default App;
