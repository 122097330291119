import { useEffect, useState } from "react";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
  } from "react-router-dom";

export default function Return() {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
  
      fetch(`https://languagesimp.com/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }, []);
  
    if (status === 'open') {
      return (
        <Navigate to="/" />
      )
    }
  
    if (status === 'complete') {
        document.getElementById("page-header").style.display = "none";
        document.getElementById("main-article").style.display = "none";
        document.getElementById("main-footer").style.display = "none";

      return (
          <div className="noise" id="success-wrapper">
          <section id="success">
            <h1>Payment Successful</h1>
            <h2>Thanks for buying the book!</h2>
            <img id="joyful-image" src={require("./images/joyful.webp")} alt="The gigachad looking extremely joyous as he prances through a meadow of butterflies and flowers." />
          <p>
            A receipt and pdf will be sent to <b>{customerEmail}</b>. 
            <br />
            This may take a few minutes.
            <br />
            <br />
            If you have any issues, please email <a href="mailto:support@languagesimp.com"><u>support@languagesimp.com</u></a>.
          </p>
        </section>
        </div>
      )
    }
  
    return null;
  }
