function Footer() {
    return (
        <footer id="main-footer" className="noise">
            <div className="footerImagesDiv">
            </div>
            
            <div className="footerCopyrightDiv">
                <p>&copy; {new Date().getFullYear()} Language Simp</p>
            </div>

            <div className="footerLinksDiv">
                <p>
                    <a href="">Privacy Policy</a>
                </p>
            </div>
            
        </footer>
    );
}

export default Footer