import React, { useEffect } from 'react';

function Header() {
    
    /**
     * Make the stripe payment module visible.
     */
    const openStripe = () => {
        const paymentForm = document.getElementById("checkout");
        paymentForm.classList.add("visible");
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    };

    /**
     * Scroll the window to the relevant HTML section. This is used for desktop resolutions, because most desktop
     * monitors should be large enough to view an entire section at once.
     * @param {*} event
     * @param {*} targetId - The HTML section that will be scrolled to. 
     */
    const centerWindow = (event, targetId) => {
        event.preventDefault(); // Prevent default anchor behavior
        const targetDiv = document.getElementById(targetId);
        
        if (targetDiv) {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const targetTop = targetDiv.offsetTop;
            const targetHeight = targetDiv.offsetHeight;
            // Calculate the vertical position to center the window on the target div
            const scrollTo = targetTop - (windowHeight - targetHeight) / 2 - 30;
            // Scroll the window to the calculated position
            window.scrollTo({
                top: scrollTo,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const pageHeader = document.getElementById("page-header");
        const pageHeaderDouble = document.getElementById("header-double");

        const navButton = document.getElementById("nav-button");
        const navbarUlMobile = document.getElementById("navbar-ul-mobile");
        const navbarUlDesktop = document.getElementById("navbar-ul-desktop");
        const navHeader = document.getElementById("navHeader");
        const nav = document.getElementsByTagName("nav")[0];
        const navButtonLi = document.getElementById("nav-button-li");

        let pageHeaderHeight = pageHeader.getBoundingClientRect();
        let headerDoubleHeight = pageHeaderDouble.getBoundingClientRect().height;
        let windowWidth = window.innerWidth;
        let headerContainer = document.getElementById("headerContainer");
        let headerCopyContainer = document.getElementById("headerCopyContainer");
        const navbar = document.getElementsByTagName("nav")[0];
        let navbarHeight = navbar.clientHeight;
        
        let scrollPositionThreshold = headerDoubleHeight - navbarHeight;

        if (windowWidth < 1100) {
            scrollPositionThreshold = headerDoubleHeight - 65;
            document.getElementById("new-language-text").innerHTML = "Why Learn a New<br />Language?";
        } else {
            document.getElementById("new-language-text").innerHTML = "Why Learn a New Language?";
        }


        /**
         * Reformats the page layout whenever the window is resized.
         */
        const handleResize = () => {    
            windowWidth = window.innerWidth;
            navbarHeight = navbar.clientHeight;
            headerDoubleHeight = pageHeaderDouble.getBoundingClientRect().height;

            scrollPositionThreshold = headerDoubleHeight - navbarHeight;
            if (windowWidth < 1100) { scrollPositionThreshold = headerDoubleHeight - 65; }

            pageHeaderHeight = pageHeader.getBoundingClientRect();
            document.documentElement.style.setProperty('--dynamic-margin-top', `${headerDoubleHeight + 60}px`);

            handleScroll();
        };
        window.addEventListener("resize", handleResize);

        /**
         * Raises the header and turns it into a navbar when the window scrolls to the lower position (positioned 
         * at the top of the screen) equal to the pre-determined navbar height.
         */
        const raiseHeader = () => {         
            pageHeader.style.position = "fixed";
            pageHeader.style.top = `${-(scrollPositionThreshold - 65)}px`;
            pageHeader.style.marginBottom = `${scrollPositionThreshold}px`;
            pageHeader.style.height = `${scrollPositionThreshold}px`;

            navButton.style.visibility = "visible";
            navButton.style.opacity = 100;
            navButton.style.maxHeight = "100%";
            navButton.style.maxWidth = "100%";
            navButton.style.transition = "opacity 0.5s ease-in-out";

            navbarUlMobile.style.fontSize = "medium";
            navbarUlDesktop.style.fontSize = "medium";
            navbarUlDesktop.style.margin = "0px";

            navButton.style.display = "block";
            navHeader.style.display = "block";
            
            if (windowWidth < 1100) {
                pageHeader.style.gridTemplateRows = "1fr 1fr 65px";
                navButtonLi.style.display = "flex";
                navButtonLi.style.alignItems = "center";
                navButtonLi.style.width = "100%";
                nav.style.flexWrap = "wrap";
                navbarUlMobile.style.display = "none";
                nav.style.marginTop = "0px";
            }
            if (windowWidth > 1700) {
                navbarUlDesktop.style.fontSize = "larger";
            }
        };

        /**
         * Reapplies the default header behaviour when the window scrolls up to the position where it should be 
         * positioned.
         */
        const resetHeader = () => {
            pageHeader.style.position = "absolute";
            pageHeader.style.top = `0px`;
            pageHeader.style.marginBottom = `0px`;
            pageHeader.style.height = "-webkit-fill-available";

            navButton.style.visibility = "hidden";
            navButton.style.opacity = 0;
            navButton.style.maxHeight = "0px";
            navButton.style.maxWidth = "0px";
            navButton.style.transition = "opacity 0.5s ease-in-out";

            navbarUlMobile.style.fontSize = "large";
            navbarUlDesktop.style.fontSize = "larger";

            navButton.style.display = "none";
            navHeader.style.display = "none";

            navbarUlDesktop.style.margin = "0 0 30px 0";
            pageHeader.style.gridTemplateRows = "auto 1fr 65px";
            navbarUlMobile.style.display = "none";
            navButtonLi.style.width = "100%";
            navButtonLi.style.display = "flex";

            headerContainer.style.overflow = "hidden";
            headerCopyContainer.style.overflow = "hidden";
            
            if (windowWidth < 1100) {
                pageHeader.style.gridTemplateRows = "auto 2fr 1fr";
                navButtonLi.style.width = "0px";
                navButtonLi.style.display = "none";
                navHeader.style.display = "none";
                nav.style.flexWrap = "nowrap";
                navbarUlMobile.style.display = "grid";
            }

            if (windowWidth > 1700) {
                navbarUlDesktop.style.fontSize = "x-large";
            }
        };
        
        /**
         * Check the window position whenever scrolling is active in order to determine whether the header should be
         * transformed into a navbar, and vice-versa.
         */
        const handleScroll = () => {
            console.log("Scroll position threshhold: " + scrollPositionThreshold.toString());
            const scrollYPosition = window.scrollY;
            
            if (scrollYPosition > scrollPositionThreshold) {
                raiseHeader();
            } else {
                resetHeader();
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div id="header-double"></div>

            <header className="noise" id="page-header">

                    <div id="headerContainer">
                        <h1 id="headerBook">
                            <span className="header-not-hl">The</span>
                            <br />
                            <span className="highlight-container">
                                <span className="highlight">Hyperpolyglot</span>
                            </span>
                            <br />
                            <span className="header-not-hl">Handbook</span>
                        </h1>
                        <h2 id="headerSubtitle" className="underline">
                            A Gigachad's Guide to Language Learning
                        </h2>
                    </div>
                
                <div id="headerCopyContainer">

                    <div id="ytVideo">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/r5Hi7dgqm8g?si=UdrENJl6w8_qzwLP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div id="header-buy-button">
                        <button type="button" onClick={openStripe}>Buy Now!</button>
                    </div>
                </div>

                <nav>
                    <div id="nav-button-li">

                        <h3 id="navHeader">
                            <span className="nav-header-w1_2">THE</span>                    
                            <span className="highlight-container" id="header-hl">
                                <span className="highlight">&nbsp;HYPERPOLYGLOT</span>
                            </span>
                            <br />
                            <span className="nav-header-w1_2">HANDBOOK</span>
                        </h3>

                        <div id="navbar-ul-desktop" className="navbar-ul">
                            <a href="#anchorAboutBook" onClick={(e) => centerWindow(e, "anchorAboutBook")}>About the Book</a>
                            <a href="#anchorWhy" onClick={(e) => centerWindow(e, "anchorWhy")}>Why Learn a New Language?</a>
                            <a href="#anchorWho" onClick={(e) => centerWindow(e, "anchorWho")}>Who's the Book For?</a>
                            <a href="#anchorAboutSimp" onClick={(e) => centerWindow(e, "anchorAboutSimp")}>About Me</a>
                        </div>

                        <button type="button" id="nav-button" onClick={openStripe}>Buy Now!</button>
                    
                    </div>

                    <div id="navbar-ul-mobile" className="navbar-ul">
                        <a href="#anchorAboutBook" onClick={(e) => centerWindow(e, "anchorAboutBook")}>About the Book</a>
                        <a href="#anchorWhy" onClick={(e) => centerWindow(e, "anchorWhy")}>Why Learn a New Language?</a>
                        <a href="#anchorWho" onClick={(e) => centerWindow(e, "anchorWho")}>Who's the Book For?</a>
                        <a href="#anchorAboutSimp" onClick={(e) => centerWindow(e, "anchorAboutSimp")}>About Me</a>
                    </div>
                </nav>

            </header>
        </>
    );
}

export default Header;
